<template>
    <div class="addLabelPerson scrolling">
        <a-form :form="form">
            <a-form-item label="标签名称:" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                    :disabled="true"
                    placeholder="请输入标签名称"
                    v-decorator="['name', { rules: [{ required: true, message: '请输入标签名称！' }] }]"
                />
            </a-form-item>
            <a-form-item label="添加成员:" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input v-show="false" v-decorator="['persons', {}]" />
                <div class="">
                    <div v-for="(o, i) in persons" :key="'persons' + i" class="my-item">
                        <a-tag class="tags flex0">
                            <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                            <a-button type="link" icon="close" @click="sendIds.splice(i, 1)" />
                        </a-tag>
                    </div>
                    <a-button
                        type="primary"
                        ghost
                        slot="suffix"
                        icon="user"
                        @click="choose_Persons('persons')"
                    ></a-button>
                </div>
            </a-form-item>
        </a-form>
        <div class="footer">
            <a-button @click="handleSubmit" type="primary" block>确认添加</a-button>
        </div>
    </div>
</template>
<script>
import { addLablePerson } from '@/api/modular/fileStream/documentLabelManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { initWx } from '../components/minxin'

export default {
    mixins: [initWx],
    components: {
        OpenData,
    },
    data() {
        return {
            tag: {},
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
            form: this.$form.createForm(this),
            persons: [],
        }
    },
    mounted() {
        this.tag = JSON.parse(decodeURIComponent(this.$route.query.tag))
        console.log(this.tag)
        this.form.setFieldsValue({ name: this.tag.name })
    },
    methods: {
        /**
         * 提交表单
         */
        handleSubmit() {
            const {
                form: { validateFields },
            } = this
            validateFields((errors) => {
                if (!errors) {
                    this.sub(this.persons)
                }
            })
        },
        choose_Persons(key) {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(key, null, this[key])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, key, [...result, ...resPart])
                    this.form.setFieldsValue({ [key]: result.map((j) => j.userId || j.id).join() })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'deptId' : 'userId'
        },
        sub(result) {
            const data = {
                labelId: this.tag.id,
                userArray: result.map(({ userId, corpId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                    type: Number(type === 'departmentName'),
                })),
            }
            addLablePerson(data)
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.$router.go(-1)
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
</script>
<style lang="less" scoped>
.addLabelPerson {
    padding: 12px;
    .my-item {
        display: inline-block;
    }
    .footer {
        position: fixed;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 45px;
    }
}
</style>
